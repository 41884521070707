<template>
  <div>
    <div class="container">
      <p>{{ displayMessage }}</p>
      <img v-if="isLoading" src="../assets/img/loader.gif" height="60" width="60">
      <alert-error v-if="error">
        <template v-slot:alert-error>
          {{msgError}}
        </template>
      </alert-error>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrayStartInstall',
  mounted() {
    document.title = 'Instalando a loja da Tray'

    this.$cookies.remove('Authorization');

    let store = this.$route.query.store
    let url = this.$route.query.url

    if(!store || !url) {
      this.message = 'Houve um erro ao instalar sua loja da Tray.'
      this.loading = false
      return;
    }

    this.sendTokenRequest(store, url)
  },
  data: function () {
    return {
      payload: null,
      message: 'Verificando sua loja.',
      loading: true,
      error: false,
      msgError: ""
    }
  },
  computed: {
    displayMessage: function () {
      return this.message
    },
    isLoading: function () {
      return this.loading;
    }
  },
  methods: {
    sendTokenRequest(store, url) {
      this.loading = true

      this.$http.get(
          'tray/install/start',
          {
            params: {store: store, url: url}
          }
      ).then((response) => {
        let data = response.data

        if (undefined === data.callbackUrl) {
          this.loading = false
          this.message = 'Houve um erro ao preparar a sua loja para instalar o plugin do TikTok'
          return
        }

          this.message = 'Configurando a sua loja.'
          
          if(data.storeExists == true){
            window.location = data.callbackUrl;
            return;
          }

          this.$router.replace('/?url='+data.callbackUrl);

      }).catch(error => {
        this.loading = false;
        let msg =  'Houve um erro ao preparar a sua loja para instalar o plugin do TikTok. ' + error.response.data.message;
        this.message  = msg;
        this.error = true;
        this.msgError = msg;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}
</style>
